.loading-text {
  font-size: 16px;
  font-weight: bold;
}

.dots {
  display: inline-block;
  font-size: 16px;
  animation: blink 1s steps(5, end) infinite;
}

@keyframes blink {
  0%, 20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.4em 0 0 rgba(0, 0, 0, 0), 0.8em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: black;
    text-shadow: 0.4em 0 0 rgba(0, 0, 0, 0), 0.8em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.4em 0 0 black, 0.8em 0 0 rgba(0, 0, 0, 0);
  }
  80%, 100% {
    text-shadow: 0.4em 0 0 black, 0.8em 0 0 black;
  }
}
